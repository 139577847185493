import React from 'react';
import { Col } from 'reactstrap';
import rose from '../../images/rose-ritch.png';
import './rose.scss';

function Rose() {
  return (
    <Col>
      <div className="rose-wrapper">
        <div className="image-wrapper">
          <img src={rose} className="img-thumbnail" alt="Rose Ritch"/>
          <br/>
          <span className="signature">&ndash; Rose Ritch</span>
        </div>
        <p className="slogan">Identity bullying has no place on our campus!</p>
      </div>
    </Col>
  );
}

export default Rose;