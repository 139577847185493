import React from 'react';
import ShareLinks from '../ShareLinks/share';
import { Col } from 'reactstrap';
import './petition.scss';

function Petition() {
  return (
    <Col>
      <div className="petition-wrapper">
        <h1>An Open Letter to the USC Community on Supporting Zionist Students at USC</h1>
        <p className="date-wrapper">
          <span className="date">
            August 30, 2020
          </span>
        </p>
        <ShareLinks/>
        <p>
          We, the undersigned faculty of USC, have followed the case of Rose Ritch and <a href="https://www.israellycool.com/2020/08/06/usg-vice-president-rose-ritch-resigns-over-antisemitic-attacks-because-she-is-a-zionist/" 
          target="_blank" rel="noopener noreferrer">her resignation</a> from student government with great dismay. As described in the supportive <a href="https://www.president.usc.edu/a-message-to-the-community-from-president-folt/">statement</a> by President Folt and fully detailed in the <a href="https://thirdnarrative.org/anti-zionism-antisemitism/are-you-now-or-have-you-ever-been-a-zionist" 
          target="_blank" rel="noopener noreferrer">statement by the Alliance for Academic Freedom</a> of 
          August 24th, Rose Ritch was subjected to vicious online harassment, and her qualification to 
          hold elected office was questioned on the basis of her professed Zionism.
        </p>
        <p>
          We find it unacceptable that such blatant discrimination on the basis of a student's belief, 
          identity, or national origin could take place on our campus.
        </p>
        <p>
          We reject in the strongest possible terms any and all attempts to associate Zionism with 
          such inflammatory accusations as racism, colonialism, and white supremacy, which are diametrically 
          antithetical to Zionist ideas and aims. We are appalled that such characterizations of Zionism 
          were the basis for calls for Rose Ritch’s resignation, and continue to be voiced by certain 
          organizations on this campus.
        </p>
        <p>
          As supporters of the Zionist idea — the right of the Jewish people to a homeland and self-determination—we stand 
          by the rights of all people, including Israelis and Palestinians, to freedom, dignity and peaceful coexistence, 
          and to advocate for their causes with fairness and respect on our campus and in the world.
        </p>
        <p>
          Contentious issues concerning the Middle East conflict must be engaged by encouraging students to study the history 
          of the conflict and to participate in discussions regarding its possible resolution. Only in that way can USC foster 
          a campus culture that is truly inclusive and respectful of diversity.
        </p>
        <p>
          As dedicated members of the USC faculty, we are concerned about the long-term impact of Rose Ritch's resignation 
          on the morale and well-being of supporters, or presumed supporters, of the State of Israel. This includes 
          Jewish students, faculty, and staff at USC, dedicated contributors to this campus’s excellence and 
          visibility, whose fitness to continue their service may come under attack. We call on our university’s 
          leadership to uphold the values of open and civilized debate, so that USC can continue its mission of 
          fostering excellence in education and advancing knowledge in a civil and respectful manner.
        </p>
    
        <p>Respectfully,</p>

        <ul className="list-unstyled">
          <li>ROBERT ADLER, MD, MS.Ed., Chief Medical Officer, CHLA Health System, Senior Advisor to the Chair of Pediatrics</li>
          <li>LISA AZIZ-ZADEH, Associate Professor of Psychology and Occupational Science and Occupational Therapy</li>
          <li>YEHUDA BEN-ZION, Professor of Earth Sciences, Director of Southern California Earthquake Center</li>
          <li>JACK M. BERGER, MS, MD, Ph.D, Professor Emeritus of Clinical Anesthesiology</li>
          <li>LISA M. BITEL, Dean's Professor of Religion and Professor of History</li>
          <li>DION DICKMAN, Associate Professor of Biological Sciences</li>
          <li>URI ELKAYAM, MD, Professor of Medicine</li>
          <li>SUSAN R. ESTRICH, Robert Kingsley Professor of Law and Political Science</li>
          <li>DONALD FEINSTEIN, MD, MACP, Professor of Medicine Emeritus</li>
          <li>HENRYK FLASHNER, Professor, Department of Aerospace and Mechanical Engineering</li>
          <li>MYRON F. GOODMAN, Professor Biological Sciences and Chemistry</li>
          <li>MIKE GRUNTMAN, Professor of Astronautics</li>
          <li>JEFFREY B. HIRSCH, Adjunct Professor, Annenberg School for Communications &amp; Journalism</li>
          <li>STUART J. HOFFMAN, D.M.D., MS, Adjunct Clinical Professor, Division of Orthodontics, Herman Ostrow School of Dentistry</li>
          <li>ELLIS HOROWITZ, Professor of Computer Science &amp; Electrical Engineering</li>
          <li>GABRIEL KAHN, Professor of Professional Practice</li>
          <li>ANNA I. KRYLOV, Professor of Chemistry, Member, International Academy of Quantum Molecular Science</li>
          <li>PAT LEVITT, PhD,  Chief Scientific Officer, Vice President and Director, The Saban Research Institute, Simms/Mann Chair in Developmental Neurogenetics, Children’s Hospital Los Angeles. WM Keck Provost Professor of Neurogenetic, Keck School of Medicine</li>
          <li>RICHARD LUBMAN, MD, Associate Professor of Medicine</li>
          <li>DINA MAYZLIN, Professor of Marketing</li>
          <li>GERARD MEDIONI, Professor Emeritus of Computer Science</li>
          <li>JERRY MENDEL, Emeritus Professor of Electrical Engineering</li>
          <li>DAPHNA OYSERMAN, Dean's Professor of Psychology</li>
          <li>RICHARD S. PANUSH, MD, Master, American College of Physicians, Master, American College of Rheumatology, Professor of Medicine</li>
          <li>STEFAN POLLACK, Adjunct Professor, Annenberg School of Communications</li>
          <li>HANNA REISLER, Lloyd Armstrong Jr. Professor in Science and Engineering</li>
          <li>MICHAEL RENOV, The Haskell Wexler Endowed Chair in Documentary, Professor of Cinema &amp; Media Studies, Vice Dean, Academic Affairs, School of Cinematic Arts</li>
          <li>STEVEN RICHEIMER, MD, Chief, Division of Pain Medicine, Professor of Anesthesiology &amp; Psychiatry</li>
          <li>REMO ROHS, Professor and Section Head, Quantitative and Computational Biology</li>
          <li>ILAN ROTSTEIN, Professor and Chair, Herman Ostrow School of Dentistry</li>
          <li>LUCILLE ROTSTEIN, Clinical Associate Professor, Herman Ostrow School of Dentistry</li>
          <li>DAN SCHNUR, Adjunct Professor, Annenberg School of Communications</li>
          <li>HAROLD SLAVKIN, Professor and Dean Emeritus, Herman Ostrow School of Dentistry</li>
          <li>DOUGLAS C. SOLOW, DDS, MBA, G. Donald Montgomery Professor of Dentistry, Associate Dean of Clinical Affairs, Director, Herman Ostrow School of Dentistry Faculty Practice</li>
          <li>WILLIAM STOHL, MD, PhD, Master, American College of Rheumatology, Professor of Medicine</li>
          <li>ANDREW VITERBI, Presidential Chair Professor of Electrical Engineering, Member, National Academy of Engineering</li>
          <li>CHERYL WAKSLAK, Associate Professor of Management and Organization &amp; McAlister Associate Professor in Business Administration</li>
          <li>ARIEH WARSHEL, Nobel Laureate, Dana and David Dornsife Chair in Chemistry, Member, National Academy of Sciences</li>
          <li>MARK WEINSTEIN, Associate Professor of Finance and Business Economics Emeritus</li>
          <li>RUTH WEISBERG,  Professor,  Roski School of Art and Design</li>
          <li>GARY WEXLER, Adjunct Professor, Annenberg School of Communications</li>
          <li>ALAN WILLNER, Steven and Kathryn Sample Chair in Engineering, Member, National Academy of Engineering</li>
          <li>CURT WITTIG, Paul A. Miller Professor of Letters, Arts and Sciences</li>
          <li>VLADIMIR ZELMAN, MD, PhD, Clinical Professor of Anesthesiology, Foreign Member, Russian Academy of Science</li>
        </ul>
      </div>
    </Col>
  );
}

export default Petition;