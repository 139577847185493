import React from 'react';
import { Col } from 'reactstrap';
// import './references.scss';

function References() {
  return (
    <Col>
      <div className="references-wrapper">
        <h3>References</h3>
        <ol>
          <li>
            Rose Ritch’s Resignation Letter: &nbsp;
            <a href="https://www.israellycool.com/2020/08/06/usg-vice-president-rose-ritch-resigns-over-antisemitic-attacks-because-she-is-a-zionist/"
            target="_blank" rel="noopener noreferrer">
              https://www.israellycool.com/2020/08/06/usg-vice- president-rose-ritch-resigns-over-antisemitic-attacks-because-she-is-a-zionist/
            </a>
          </li>
          <li>
            A Message to the USC Community from President Folt: &nbsp;
            <a href="https://www.president.usc.edu/a-message-to-the-community-from-president-folt/" 
            target="_blank" rel="noopener noreferrer">
              https://www.president.usc.edu/a-message-to-the-community-from-president-folt/
            </a>
          </li>
          <li>
            "ARE YOU NOW OR HAVE YOU EVER BEEN A ZIONIST?" by The Alliance for Academic Freedom: &nbsp;
            <a href="https://thirdnarrative.org/anti-zionism-antisemitism/are-you-now-or-have-you-ever-been-a-zionist"
            target="_blank" rel="noopener noreferrer">
              https://thirdnarrative.org/anti-zionism-antisemitism/are-you-now-or-have- you-ever-been-a-zionist
            </a>
          </li>
        </ol>
      </div>
    </Col>
  );
}

export default References;