import React from 'react';
import { Col } from 'reactstrap';

function Reactions() {
  return (
    <Col>
      <div className="reactions-wrapper">
        <h2>Reactions</h2>
        <ul>
          <li>
            <a href="FacultyLetter_OCR_080122.pdf">Letter from President Folt</a> (August 1, 2022).
          </li>
          <li>
            The Jewish News Syndicate
            article: <a
            href="https://www.jns.org/federal-probe-investigates-usc-for-anti-semitic-environment-against-campus-jewish-leader/"
            target="_blank"
            rel="noopener noreferrer">Federal probe investigates USC for anti-Semitic environment against campus Jewish leader</a> (July 27, 2022)
          </li>
          <li>
            The Inside Higher Ed
            article: <a
            href="https://www.insidehighered.com/news/2022/07/27/ed-launches-title-vi-investigation-usc?utm_source=Inside+Higher+Ed&utm_campaign=72098ee621-DNU_2021_COPY_02&utm_medium=email&utm_term=0_1fcbc04421-72098ee621-237003801&mc_cid=72098ee621&mc_eid=fc60cbb888"
            target="_blank"
            rel="noopener noreferrer">USC Faces Investigation Over Alleged Student Harassment</a> (July 27, 2022)
          </li>
          <li>
            The Jewish Journal
            article: <a
            href="https://jewishjournal.com/news/350269/education-dept-to-investigate-uscs-handling-of-antisemitic-harassment-against-rose-ritch/"
            target="_blank"
            rel="noopener noreferrer">Handling of Antisemitic Harassment Against Rose Ritch</a> (July 26, 2022)
          </li>
          <li>
            The Algemeiner
            article: <a
            href="https://www.algemeiner.com/2022/07/26/university-of-southern-california-faces-federal-probe-over-harassment-of-pro-israel-student-leader/"
            target="_blank"
            rel="noopener noreferrer">University of Southern California Faces Federal Probe Over Harassment of Pro-Israel Student Leader</a> (July 26, 2022)
          </li>
          <li>
            The Jewish Insider
            article: <a
            href="https://jewishinsider.com/2022/07/department-of-education-to-open-investigation-into-usc-over-antisemitism-allegations/"
            target="_blank"
            rel="noopener noreferrer">Department of Education to open investigation into USC over antisemitism allegations</a> (July 26, 2022)
          </li>
          <li>
            The Times of Israel
            article: <a
            href="https://www.timesofisrael.com/federal-probe-opened-into-alleged-antisemitism-at-university-of-southern-california/"
            target="_blank"
            rel="noopener noreferrer">Federal probe opened into alleged antisemitism at University of Southern California</a> (July 26, 2022)
          </li>
          <li>
            The Heterodox STEM
            article: <a
            href="https://hxstem.substack.com/p/on-cancel-culture-and-antisemitism-3df"
            target="_blank"
            rel="noopener noreferrer">On cancel culture and antisemitism in academia: part II</a> (February 25, 2022)
          </li>
          <li>
            The Daily Trojan
            article: <a
            href="https://dailytrojan.com/2022/01/25/tweets-letters-stir-anger/"
            target="_blank"
            rel="noopener noreferrer">"Tweets, letters stir anger"</a> (January 25, 2022)
          </li>
          <li>
            The Jewish News Syndicate
            article: <a
            href="https://www.jns.org/usc-administrations-response-to-anti-semitism-comes-under-the-microscope/"
            target="_blank"
            rel="noopener noreferrer">"USC administration’s response to anti-Semitism comes under the microscope"</a> (December 30, 2021)
          </li>
          <li>
            Our Letter is mentioned in an <a href="https://usc-faaz-12-2021.org" target="_blank" rel="noopener noreferrer">Open Letter calling on USC's leadership to explicitly
            rebuke open expressions of  anti-Semitism and Zionophobia on USC campus</a> (December 1, 2021).
          </li>
          <li>The Letter is mentioned in a <a href="https://usc-faaz-2021.org" target="_blank" rel="noopener noreferrer">new Open Letter</a> to
            the Leadership of USC (August 8, 2021).
          </li>
          <li>Daily Trojan published <a href="https://dailytrojan.com/2020/10/02/letter-to-the-editor-following-rose-ritch-resignation-uscs-climate-of-inclusion-must-be-redefined-to-include-zionism/" target="_blank" rel="noopener noreferrer">
            OpEd</a> calling attention to the Open Letter (October 2).
          </li>
          <li>Judea Pearl mentions the Open Letter in <a href="https://jewishjournal.com/culture/321992/ginsburg-on-being-jewish/" target="_blank" rel="noopener noreferrer">his commentary in
            memory of Ruth Bader Ginsburg</a> published in Jewish Journal (September, 23).
          </li>
          <li><a href="200916_Open_Letter_Response.pdf">Reply from Provost Zukoski</a> (September 16).</li>
          <li>Inside Higher Ed article by Greta Anderson <a href="https://www.insidehighered.com/news/2020/09/09/anti-semitism-rise-new-semester-starts" target="_blank" rel="noopener noreferrer">
            "Responding to Rise in Campus Anti-Semitism"
            </a> (September 9) mentions the USC case and quotes from the Open Letter.
          </li>
          <li>Editorial by  David Suissa <a href="https://jewishjournal.com/commentary/columnist/editors-note/321257/i-am-a-zionist-is-new-frontier-of-israel-activism/" target="_blank" rel="noopener noreferrer">
            "I’m a Zionist’ Is New Frontier of Israel Activism"</a> published in Jewish Journal (September 4) discussed the Open Letter.
          </li>
          <li>The Algemeiner ariticle <a href="https://www.algemeiner.com/2020/08/27/progressive-academics-voice-support-for-jewish-usc-student-over-anti-zionist-harassment/" target="_blank" rel="noopener noreferrer">"Progressive
            Academics Voice Support for Jewish USC Student Over Anti-Zionist Harassment"
            </a> discussed the Open Letter. The Algemeiner also reprinted the Open Letter as an <a href="https://www.algemeiner.com/2020/09/02/an-open-faculty-letter-to-the-usc-community-on-supporting-zionist-students-at-usc/" target="_blank" rel="noopener noreferrer">OpEd</a>.
          </li>
        </ul>
      </div>
    </Col>
  );
}

export default Reactions;
