import React from 'react';
import Header from './components/Header/header';
import Petition from './components/Petition/petition';
import References from './components/References/references';
import Rose from './components/Rose/rose';
import Reactions from './components/Reactions/reactions'
import { Container, Row } from 'reactstrap';

import './App.scss';

function App() {
  return (
    <div className="App">
      <Container>
        <Row>
          <Header />
        </Row>
        <Row>
          <Petition />
        </Row>
        <Row>
          <References />
        </Row>
        <Row>
          <Rose />
        </Row>
        <Row>
          <Reactions />
        </Row>
      </Container>
    </div>
  );
}

export default App;
